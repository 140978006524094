'use strict';
var $ = jQuery = require('jquery-browserify');


(function($){
   
    $('.lost').click(function () {
        $('.loginBox').hide();
        $('.forgotBox').show();
    });

    $('.back').click(function () {
        $('.loginBox').show();
        $('.forgotBox').hide();
    });
    
    $('.loginForm').submit(function(){
        if ( $('input.uname').val().length == 0 || $('input.pass').val().length == 0 ) {
            $('.error').addClass('show');
            return false;
        }
    });
    
    
})(jQuery);